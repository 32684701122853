//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { jSith } from "../../util/jquery-replacement";
import { DropdownApi } from "../s25-dropdown/dropdown.api";
import { UserprefService } from "../../services/userpref.service";
import { PreferenceService } from "../../services/preference.service";
import { Preference } from "../../pojo/PeferenceI";
import { DropDownPaginatedModel } from "../../pojo/DropDownPaginatedModel";
import { TimePickerModel } from "./s25.timepicker";

@TypeManagerDecorator("s25-timepicker")
@Component({
    selector: "s25-timepicker",
    template: `
        <span class="s25-ng ngTimepicker">
            <span *ngIf="this.init">
                <input
                    class="ngTimepickerInput cn-form__control"
                    aria-label="time"
                    [id]="this.inputId"
                    type="text"
                    name="this.inputId"
                    [(ngModel)]="this.modelBean.timeText"
                    (change)="onInputFieldChange($event)"
                />
                <div style="width: 230px !important">
                    <s25-dropdown-paginated
                        [hidden]="!this.modelBean.showTimes"
                        [choice]="choice"
                        [(chosen)]="this.modelBean.chosenTime"
                        [model]="this.modelBean.dropdownModel"
                        [onSelect]="this.timeSelected"
                    ></s25-dropdown-paginated>
                </div>
            </span>
        </span>
        <ng-template #choice let-item="item">
            <div [innerHTML]="item.itemName"></div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25TimepickerComponent implements OnInit, OnChanges {
    @Input() modelValue?: any;
    @Input() modelBean?: TimePickerModel;
    @Input() defaultNow?: boolean;
    @Input() defaultZero?: boolean;
    @Input() inputLabel: string = undefined;
    @Input() inputId: string = undefined;
    @Input() step: number = 30;
    @Input() prefType: Preference.PrefType = "U";
    @Input() useAllHours?: boolean = false;

    @Output() modelValueChange = new EventEmitter();

    init: boolean = false;
    initEmpty: boolean = false;
    times: any[] = [];
    timeFormat: string;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;

        jSith.on(this.elementRef.nativeElement, "focus click", (e: any) => {
            //dropdown selections register as elementRef, so dropdown reopens after selection is made - pointerId is -1 if clicking dropdown selection, avoiding reopen
            if (e.pointerId === -1) return;

            //on focus
            e.stopPropagation();
            e.preventDefault();
            this.openDropdown();
        });

        jSith.on(this.elementRef.nativeElement, "keydown", (e: any) => {
            //on focus

            switch (e.keyCode) {
                case 38: //up arrow
                    DropdownApi.arrow(this.elementRef.nativeElement, "up");
                    break;
                case 40: //down arrow
                    DropdownApi.arrow(this.elementRef.nativeElement, "down");
                    break;
                case 13: //enter
                    // if(this.modelBean.dropdownModel.getHighlightedItem()) {
                    //     DropdownApi.enterSelect(this.elementRef.nativeElement);
                    // } else {
                    //     this.setTimeFromInput();
                    // }   commoment out this, got error when click cancel button from parent component
                    this.setTimeFromInput();
                    break;
                case 9: //tab
                    this.setTimeFromInput();
                    break;
                default: //other key (close dropdown)
                    this.closeDropdown();
                    break;
            }
        });

        jSith.on(this.elementRef.nativeElement, "blur", (e: any) => {
            if (!this.modelBean.dropdownModel.isDropdownOpen()) {
                this.setTimeFromInput();
            }
        });

        jSith.on(document.body, "click", (e: any) => {
            if (this.modelBean.timeText) {
                let dateTime = S25Util.date.parseFuzzyTimeString(this.modelBean.timeText, this.useAllHours);
                if (!dateTime) {
                    dateTime = S25Util.date.clone(this.modelValue);
                    this.modelBean.timeText = "0:00";
                }
            }
            this.closeDropdown();
        });
        // fixed ANG-3828
        jSith.on(this.elementRef.nativeElement, "mouseout", (e: any) => {
            if (
                !this.modelBean.dropdownModel &&
                !this.modelBean.dropdownModel.isDropdownOpen() &&
                this.modelBean.timeText
            ) {
                this.onInputFieldChange(this.modelBean.timeText);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.modelValue) {
            this.parseDate();
            if (!changes.modelValue.firstChange) this.setInputFromTime();
        }
    }

    ngOnInit() {
        this.modelBean = this.modelBean || {};
        this.modelBean.timeText = "";
        this.initEmpty = S25Util.toBool(this.modelBean.initEmpty);

        //START set modelValue
        if (!this.modelValue && this.defaultNow) {
            this.modelValue = new Date();
            this.modelValueChange.emit(this.modelValue);
        } else if (!this.modelValue && this.defaultZero) {
            this.modelValue = new Date();
            this.modelValue.setHours(0);
            this.modelValue.setMinutes(0);
            this.modelValueChange.emit(this.modelValue);
        } else if (!this.modelValue && !this.initEmpty) {
            this.modelValue = new Date();
            this.modelValueChange.emit(this.modelValue);
        }

        if (this.modelValue && this.modelValue.indexOf) {
            this.parseDate();
        }

        if (this.modelValue) {
            this.modelValue.setSeconds(0);
            this.modelValue.setMilliseconds(0);
        }
        //END set modelValue

        if (this.prefType === "U") {
            return UserprefService.getS25Timeformat().then((timeFormat) => {
                this.timeFormat = timeFormat;
                this.initFn();
            });
        } else {
            return S25Util.all({
                pref: PreferenceService.getPreferences(["TimeDisplay"], this.prefType),
            }).then((resp) => {
                let timeDisplay = resp.pref.TimeDisplay.value;
                parseInt(timeDisplay) === 24 ? (this.timeFormat = "S25|H:mm") : (this.timeFormat = "S25|h:mm a");
                this.initFn();
            });
        }
    }

    initFn = () => {
        this.times = [];
        this.modelBean.chosenTime = null;
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += this.step) {
                let date = (this.modelValue && S25Util.date.clone(this.modelValue)) || new Date();
                date.setHours(hour);
                date.setMinutes(minute);
                date.setSeconds(0);
                date.setMilliseconds(0);

                let itemName = S25Datefilter.transform(date, this.timeFormat);
                let dateTimeObj: any = {
                    date: date,
                    itemName: itemName,
                    txt: itemName, //somehow dropdown needs this too bc someone itemName gets stripped from its chosen binding but I have no idea why
                    itemId: Number(date),
                };
                this.times.push(dateTimeObj);
            }
        }

        this.updateChosenTime();

        this.modelBean.dropdownModel = {
            placeholder: "Select a Time",
            itemNameProp: "itemName",
            items: this.times,
            scrollToSelected: true,
            pageSize: 999,
        };

        this.setInputFromTime();
        this.init = true;
        this.cd.detectChanges();
    };

    updateTime = (newDateTime: any) => {
        let prev = this.modelValue && S25Util.date.clone(this.modelValue);
        this.closeDropdown();
        this.modelValue = this.modelValue || newDateTime;
        if (this.modelValue) {
            this.modelValue = S25Util.date.syncDateToTime(this.modelValue, newDateTime);
            this.setInputFromTime(this.modelValue);
            this.updateChosenTime();
            //aways emit if we initEmpty otherwise first change isn't detected by parent
            if (
                this.initEmpty ||
                (prev && S25Util.date.toS25ISOTimeStr(prev) !== S25Util.date.toS25ISOTimeStr(this.modelValue))
            ) {
                this.modelValueChange.emit(this.modelValue);
            }
        }
        this.cd.detectChanges();
    };

    setTimeFromInput = () => {
        if (this.modelBean.timeText) {
            let dateTime = S25Util.date.parseFuzzyTimeString(this.modelBean.timeText, this.useAllHours);
            if (!dateTime) {
                dateTime = S25Util.date.clone(this.modelValue);
            }
            this.updateTime(dateTime);
        }
    };

    setInputFromTime = (date?: any) => {
        date = date || this.modelValue;
        this.modelBean.timeText = (date && S25Datefilter.transform(date, this.timeFormat)) || "";
        this.cd.detectChanges();
    };

    updateChosenTime = (round?: boolean) => {
        this.modelBean.chosenTime = null;
        let comparisonDate = (this.modelValue && S25Util.date.clone(this.modelValue)) || new Date();

        for (let i = 0; i < this.times.length; i++) {
            if (S25Util.date.toS25ISOTimeStr(this.times[i].date) === S25Util.date.toS25ISOTimeStr(comparisonDate)) {
                this.modelBean.chosenTime = this.times[i];
                break;
            } else if (round) {
                let roundedDate = S25Util.date.clone(comparisonDate);
                roundedDate.setMinutes(Math.round(roundedDate.getMinutes() / this.step) * this.step);
                if (S25Util.date.toS25ISOTimeStr(this.times[i].date) === S25Util.date.toS25ISOTimeStr(roundedDate)) {
                    this.modelBean.chosenTime = this.times[i];
                    break;
                }
            }
        }

        if (!this.modelBean.chosenTime && !round) {
            this.updateChosenTime(true);
        }

        this.cd.detectChanges();
    };

    closeDropdown = () => {
        if (this.modelBean.showTimes === true) {
            this.modelBean.showTimes = false;
            DropdownApi.close(this.elementRef.nativeElement);
            this.cd.detectChanges();
        }
    };

    openDropdown = () => {
        this.modelBean.showTimes = true;
        DropdownApi.open(this.elementRef.nativeElement);
        this.cd.detectChanges();
    };

    timeSelected = () => {
        this.updateTime(this.modelBean.chosenTime.date);
    };

    // ANG-3828 manually enter new times, without tab, enter
    onInputFieldChange = (e: any) => {
        if (e && e.target) {
            this.modelBean.timeText = e.target.value;
        } else {
            this.modelBean.timeText = e;
        }
        let dateTime = S25Util.date.parseFuzzyTimeString(this.modelBean.timeText, this.useAllHours);
        if (dateTime) {
            this.setTimeFromInput();
            this.modelValueChange.emit(this.modelValue);
        }
    };

    parseDate() {
        if (/^\d+:\d+(:\d+)*$/.test(this.modelValue)) {
            //handle modelValue as a string like 11:49
            let timeString = this.modelValue.split(":");
            let modelDate = new Date();
            modelDate.setHours(timeString[0]);
            modelDate.setMinutes(timeString[1]);
            this.modelValue = modelDate;
        } else if (S25Util.date.isValid(this.modelValue)) {
            //try to parse string as date...
            this.modelValue = S25Util.date.parse(this.modelValue);
        }
    }
}
