//@author: nichole

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-toggle-button")
@Component({
    selector: "s25-toggle-button",
    template: `<div>
        <div class="toggle-wrapper">
            <div class="toggle toggle--private" role="radiogroup" [id]="id + id2" [attr.aria-label]="toggleLabel">
                <input
                    [id]="id"
                    [attr.name]="id"
                    name="{{ id }}"
                    [ngClass]="{ ngChecked: modelValue === false, ngUnchecked: modelValue }"
                    class="toggle__radio toggle__radio--1"
                    type="radio"
                    [value]="false"
                    role="radio"
                    [attr.aria-label]="falseLabel"
                    [attr.aria-checked]="modelValue === false ? 'true' : 'false'"
                    [(ngModel)]="modelValue"
                    (ngModelChange)="onChangeHandler($event)"
                    [disabled]="disabled"
                />

                <input
                    [id]="id3"
                    [attr.name]="id"
                    name="{{ id }}"
                    *ngIf="modelValue === undefined"
                    class="toggle__radio toggle__radio--3 ngChecked"
                    type="radio"
                    [value]="undefined"
                    role="radio"
                    [attr.aria-label]="'unset'"
                    [attr.aria-checked]="true"
                    [ngModel]="modelValue"
                    (ngModelChange)="onChangeHandler($event)"
                    [disabled]="disabled"
                />

                <input
                    [id]="id2"
                    [attr.name]="id"
                    name="{{ id }}"
                    [ngClass]="{ ngChecked: modelValue, ngUnchecked: modelValue === false }"
                    class="toggle__radio toggle__radio--2"
                    type="radio"
                    [value]="true"
                    role="radio"
                    [attr.aria-label]="trueLabel"
                    [attr.aria-checked]="modelValue === true ? 'true' : 'false'"
                    [ngModel]="modelValue"
                    (ngModelChange)="onChangeHandler($event)"
                    [disabled]="disabled"
                />

                <label [for]="id" class="toggle__label toggle__label--1">{{ finalFalseLabel }}</label>
                <div class="toggle__knob" role="presentation"></div>
                <label [for]="id2" class="toggle__label toggle__label--2">{{ finalTrueLabel }}</label>
            </div>
        </div>
    </div>`,
    styles: `
        s25-toggle-button [role="radiogroup"] {
            width: fit-content;
        }

        s25-toggle-button input[disabled],
        s25-toggle-button input[disabled] ~ * {
            cursor: not-allowed !important;
        }

        s25-toggle-button input[disabled] ~ label {
            color: rgba(0, 0, 0, 0.5) !important;
        }

        s25-toggle-button input[disabled] ~ .toggle__knob {
            background-color: rgba(0, 0, 0, 0.26) !important;
        }

        s25-toggle-button input[disabled] ~ .toggle__knob::after {
            background-color: #e0e0e0 !important;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ToggleButtonComponent implements OnInit {
    @Input() modelValue: any = undefined;
    @Input() onChange: (obj: any) => any = undefined;
    @Input() description: string = undefined;
    @Input() falseLabel: string = undefined;
    @Input() trueLabel: string = undefined;
    @Input() toggleLabel: string = undefined;
    @Input() disabled: boolean = false;

    @Output() modelValueChange = new EventEmitter();

    static toggleCount: number = 0;

    defaultDesc: string = "Toggle button";
    defaultFalseLabel: string = "No";
    defaultTrueLabel: string = "Yes";
    id: string;
    id2: string;
    id3: string;
    finalFalseLabel: string;
    finalTrueLabel: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        S25ToggleButtonComponent.toggleCount++;
        this.id = "ngToggleButton" + S25ToggleButtonComponent.toggleCount;
        S25ToggleButtonComponent.toggleCount++;
        this.id2 = "ngToggleButton" + S25ToggleButtonComponent.toggleCount;
        S25ToggleButtonComponent.toggleCount++;
        this.id3 = "ngToggleButton" + S25ToggleButtonComponent.toggleCount;
    }

    public onChangeHandler(newValue: any) {
        newValue = S25Util.isUndefined(newValue) ? undefined : S25Util.toBool(newValue);
        this.onChange && S25Util.isFunction(this.onChange) && this.onChange(newValue);
        this.modelValueChange.emit(newValue);
        this.modelValue = newValue;
        this.cd.detectChanges();
    }

    modelValueSetter(newValue: any) {
        if (newValue !== this.modelValue) {
            this.onChangeHandler(newValue);
        }
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;
        // set descr, fall back to generic desc
        this.description = this.description || this.defaultDesc;
        //Final labels because undefined @ bindings come in as empty strings and one time bindings never update
        this.finalFalseLabel = this.falseLabel || this.defaultFalseLabel;
        this.finalTrueLabel = this.trueLabel || this.defaultTrueLabel;
        this.modelValue = S25Util.isUndefined(this.modelValue) ? undefined : S25Util.toBool(this.modelValue);
    }
}
